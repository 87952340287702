"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = removeComments;

var _constants = require("../constants");

function removeComments(node) {
  _constants.COMMENT_KEYS.forEach(key => {
    node[key] = null;
  });

  return node;
}