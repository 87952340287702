"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isLet;

var _generated = require("./generated");

var _constants = require("../constants");

function isLet(node) {
  return (0, _generated.isVariableDeclaration)(node) && (node.kind !== "var" || node[_constants.BLOCK_SCOPED_SYMBOL]);
}