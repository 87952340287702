"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = cloneDeep;

var _cloneNode = require("./cloneNode");

function cloneDeep(node) {
  return (0, _cloneNode.default)(node);
}