"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = inheritTrailingComments;

var _inherit = require("../utils/inherit");

function inheritTrailingComments(child, parent) {
  (0, _inherit.default)("trailingComments", child, parent);
}